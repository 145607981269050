import type { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> { }

const LeftArrow: React.FC<Props> = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-arrow-left"
            {...props}
        >
            <path d="M12 19l-7-7 7-7" />
            <path d="M19 12H5" />
        </svg>
    )
}

export default LeftArrow