import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import Button from '@elements/button';
import LeftArrow from '@svg/LeftArrow'
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DonationInfoDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const t = useTranslations();

  return (
    <>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 backdrop-brightness-50 z-[60]"
          onClick={onClose}
        />
      </Transition>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-full"
      >
        <div className="fixed right-0 top-0 h-full w-screen md:max-w-[643px] bg-[#732980] z-[61] text-white overflow-y-auto">
          {/* svg Background*/}
            <div className="absolute inset-0 -z-[10]" style={{
              backgroundImage: 'url(/svgs/ramadan-bg3.svg)',
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              opacity: .15
            }} />

          <div className="p-6 h-full flex flex-col">
            <div className="flex gap-2 items-center mb-8">
              <div onClick={onClose} className='cursor-pointer'>
                <LeftArrow />
              </div>
              <h1 className="text-lg font-bold">
                {t('How Daboobs Donations Work')}
              </h1>
            </div>

            <section className="mb-8">
              <h2 className="text-xl font-extrabold mb-4">
                {t('WRAPPING DONATION INITIATIVE')}
              </h2>
              <p className="font-semibold mb-4">
                {t('Skip the Wrap Share the Love')}
              </p>
              <p className="mb-4">
                {t('This Ramadan Daboob is offering a unique way to contribute')}
                {' '}
                {t('By opting out of gift wrapping youre helping us redirect the saved cost to support children in need')}
              </p>
              <p className="mb-4">
                {t('Every small choice helps Daboob give back to children in need during this special month')}
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-extrabold mb-4">
                {t('WHY PARTICIPATE')}
              </h2>
              <p className="mb-4">
                {t('By joining Daboobs Ramadan campaign youre not just shopping or decluttering youre making a real difference in the lives of children')}
                {' '}
                {t('Every toy donated and every cost saved contributes to a larger mission of spreading joy and kindness')}
              </p>
              <p className="font-bold">
                {t('Thank You for Playing It Forward with Daboob')}
              </p>
            </section>

            <Button
              onClick={onClose}
              className="w-full rounded-full mt-auto bg-[#F8D5F3] text-[#732980] font-extrabold hover:text-[#732980]"
            >
              {t('_Start Shopping')}
            </Button>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default DonationInfoDrawer; 