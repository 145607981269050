import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React, { useState } from 'react';
import DonationInfoDrawer from './DonationInfoDrawer';
interface Props {
    donations_count?:number;
}
const HomeDonationBanner = ({donations_count}:Props) => {
    const t = useTranslations();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <>
            <div
                className="relative w-full overflow-hidden rounded-xl cursor-pointer"
                onClick={() => setIsDrawerOpen(true)}
            >
                {/* Background pattern */}
                <div className="absolute inset-0 bg-[#ED2E7B]">
                    <div className="absolute inset-0" style={{
                        backgroundImage: 'url(/svgs/ramadan-bg2.svg)',
                        backgroundRepeat: 'repeat',
                        backgroundSize: 'cover',
                        opacity: 1
                    }} />
                </div>

                {/* Content */}
                <div className="flex justify-between items-center">
                    <div className="relative px-4 py-6 text-white">
                        <h2 className="md:text-4xl sm:text-3xl text-2xl font-extrabold mb-2 uppercase">
                            {t('SKIP THE WRAP SHARE THE LOVE')}
                        </h2>
                        <p className="text-sm mb-2">
                            {t('Bring happiness to children this Ramadan')}
                        </p>
                        <h2 className="text-lg sm:text-xl font-extrabold uppercase">
                            {t('Number of toys unwrapped')}
                        </h2>
                        <h2 className="text-xl font-extrabold mb-2 uppercase">
                            {donations_count}
                        </h2>
                    </div>
                    <div className="h-[169px] w-[104px] scale-90 mx-8 hidden md:block">
                        <Image
                            src="/imgs/heart-bear.png"
                            alt="heart-bear"
                            width={104}
                            height={169}
                            priority
                        />
                    </div>
                </div>
            </div>

            <DonationInfoDrawer
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            />
        </>
    );
}

export default HomeDonationBanner;